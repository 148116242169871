import React, {useState, useEffect} from 'react'
import { graphql } from 'gatsby'
import { SliceZone } from '@prismicio/react'

import Layout from '../components/Layout'
import { Seo } from '../components/Seo'
import { components } from '../slices/find-your-wine'

//components
import FindYourWineBanner from '../components/FindYourWineBanner'

//preview
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

const FindYourWinePage = ({ data }) => {
    /* REFORMAT DATA FOR SLICEZONE DATA */
    const pageData = data.prismicFindYourWinePage.data
    const [sliceData, setSliceData] = useState([])

    useEffect(() => {
        /* ADD MODULES AND SECTIONS TO SLICEDATA */
        const newSliceData = []

        // Push Whats my wine module
        let moduleWhatsMyWine = data.prismicWhatsMyWineModule.data
        var whatsMyWineSliceData = {
            slice_type: "whats_my_wine_module",
            primary: {
                section_title: moduleWhatsMyWine.section_title,
                section_desc: moduleWhatsMyWine.section_description,
                sub_section: moduleWhatsMyWine.sub_section,
                homepage: false
            }
        }
        var checkWhatsMyWineDuplicateSliceData = newSliceData.map((item) => {
          if(item.slice_type === "whats_my_wine_module") return true;
          return false;
        })
        if(!checkWhatsMyWineDuplicateSliceData.includes(true)) newSliceData.push(whatsMyWineSliceData)
        // Eof push what's my wine module

        // Where to buy module
        let moduleWhereToBuy = data.prismicWhereToBuyModule.data
        let whereToBuySliceData = {
            slice_type: "where_to_buy_module",
            primary: {
                section_title : moduleWhereToBuy.section_title,
                section_desc: moduleWhereToBuy.section_description,
                button_link: moduleWhereToBuy.button_link,
                button_link_text: moduleWhereToBuy.button_link_text,
                section_image: moduleWhereToBuy.section_image
            }
        }
        var checkWhereToBuyDuplicateSliceData = newSliceData.map((item) => {
          if(item.slice_type === "where_to_buy_module") return true;
          return false;
        })
        if(!checkWhereToBuyDuplicateSliceData.includes(true))  newSliceData.push(whereToBuySliceData)

        // Our Brands Module
        var moduleOurBrand = data.prismicOurBrandsSection.data
        var ourBrandSliceData = {
            slice_type: "our_brands_section",
            primary: {
              section_title: moduleOurBrand.section_title,
              section_desc: moduleOurBrand.section_text_field,
              brand_link_image_text_group: moduleOurBrand.brand_link_image_text_group
            }
        }
        var checkOurBrandDuplicateData = newSliceData.map((item) => {
          if(item.slice_type === "our_brands_section") return true;
          return false;
        })
        if(!checkOurBrandDuplicateData.includes(true))  newSliceData.push(ourBrandSliceData)

        setSliceData(newSliceData)

        //Scroll to the top on new page
        document.documentElement.style.scrollBehavior = 'auto'
        window.scrollTo(0,0)
    }, [data.prismicOurBrandsSection.data, data.prismicWhatsMyWineModule.data, data.prismicWhereToBuyModule.data])

    return(
        <Layout currentPage="find-your-wine" customPage={true}>
            <Seo
                title={(pageData.meta_title)? pageData.meta_title.text : null}
                description={(pageData.meta_description)? pageData.meta_description.text : null}
            />
            <FindYourWineBanner 
              title={pageData.banner_title.text}
              backgroundUrl={pageData.banner_background.url}
              description={pageData.banner_description.html}
              mobileBackgroundUrl={pageData.banner_background__mobile_.url}
            />
            <SliceZone slices={sliceData} components={components} />
        </Layout>
    )
}

export default withPrismicPreview(FindYourWinePage)

export const query = graphql`
    query FindYourWinepageQuery {
        prismicFindYourWinePage {
            _previewable
            data {
                banner_description {
                  text
                  html
                }
                banner_title {
                  text
                }
                meta_description {
                  text
                }
                meta_title {
                  text
                }
                banner_background {
                  url
                  alt
                }
                banner_background__mobile_{
                  url
                  alt
                }
            }
        }
        prismicOurBrandsSection{
            _previewable
            data {
              section_title {
                text
              }
              section_text_field {
                text
              }
              brand_link_image_text_group {
                brand_image {
                  alt
                  copyright
                  url
                }
                brand_link {
                  size
                  slug
                  tags
                  target
                  type
                  uid
                  url
                  link_type
                  lang
                  isBroken
                  id
                }
                brand_name {
                  richText
                  text
                  html
                }
              }
            }
        }
        prismicWhereToBuyModule {
            _previewable
            data {
              section_title {
                text
              }
              section_description {
                text
                html
              }
              button_link {
                raw
                size
                slug
                tags
                target
                type
                uid
                url
                link_type
                lang
                isBroken
                id
              }
              button_link_text {
                text
              }
              section_image {
                alt
                url
              }
            }
        }
        prismicWhatsMyWineModule {
            _previewable
            data {
              section_title {
                text
              }
              section_description {
                text
                html
              }
              sub_section {
                sub_section_title {
                  text
                }
                sub_section_description {
                  text
                  html
                }
                sub_section_image{
                  alt
                  url
                }
                sub_section_button_text{
                  text
                }
                sub_section_button_link{
                  raw
                  size
                  slug
                  tags
                  target
                  type
                  uid
                  url
                  link_type
                  lang
                  isBroken
                  id
                }
              }
            }
        }
    }
`