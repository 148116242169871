import React, {useRef, useState, useEffect} from 'react'
import { Link } from 'gatsby'

import { isElementInViewport } from '../components/functions'

export const WhatsMyWineModule = ({slice}) => {
    const [isInView, setIsInView] = useState(false)
    const ref = useRef(null)

    const data = slice.primary;

    //create an event listener
    useEffect(() => {
        if(isElementInViewport(ref.current)){
            setIsInView(isElementInViewport(ref.current))
        }
        window.addEventListener('scroll',() => {
            if(isElementInViewport(ref.current)){
                setIsInView(true)
            }
        })
    }, [])

    const clickDatalayer = (data) => {
        //Data layer - Learn more clicked
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push(data)
    }

    return (
        <section className={(isInView)?'whats-my-wine-module py-0 py-lg-5 in-view':'whats-my-wine-module py-0 py-lg-5'} ref={ref}>
            {(data.homepage)?
                // Homepage
                <div className="container py-5">
                    <div className='tlt-row row slide-up'>
                        <div className='tlt-wrapper col-lg-5'>
                            <h2>{data.section_title.text}</h2>
                        </div>
                        <div className="col-lg-7 d-flex align-items-center">
                            <div className='desc-wrapper text-right w-100 pt-3 pt-lg-0' dangerouslySetInnerHTML={{__html: data.section_desc.html}}/>
                        </div>
                    </div>
                </div> :
                // find-your-wine page
                <div className="container py-4">
                    <div className='tlt-row slide-up'>
                        <div className="d-flex align-items-center">
                            <div className='desc-wrapper text-left w-100 pt-3 pt-lg-0' dangerouslySetInnerHTML={{__html: data.section_desc.html}}/>
                        </div>
                    </div>
                </div>
            }
            <div className='container pb-3 container--sub-section'>
                <div className="row justify-content-center sub-section-wrapper pb-0 pb-lg-5">
                    {data.sub_section.map((item, index) => {
                        return (
                            <div key={`col-${index}`} className={'col-lg-4 whats-my-wine-item px-3 slide-up animation-delay-' + (index+1)}>
                                <div className='item-img mb-3 d-flex justify-content-center align-items-center'>
                                    <img src={item.sub_section_image.url} width="100%" alt={(item.sub_section_image.alt)? item.sub_section_image.alt : item.sub_section_title.text} />
                                </div>
                                <div className='item-content pb-5 pb-lg-0'>
                                    <h3 className='mt-4 font-title'>{item.sub_section_title.text}</h3>
                                    <p className='desc mt-2'>
                                        {item.sub_section_description.text}
                                    </p>
                                    {(item.sub_section_button_link.link_type === "Document" && item.sub_section_button_link.uid)?
                                        <Link 
                                            to={"/" + item.sub_section_button_link.uid}
                                            onClick={() => {
                                                let pageHost = new URL(window.location.href)
                                                let pageUrl = pageHost.host
                                                pageUrl +=  "/" + item.sub_section_button_link.uid

                                                let dl = {
                                                    'event': 'learn_more_clicked',
                                                    'page_name': pageUrl,
                                                    'referral': (pageHost.pathname.replace('/', '') === "find-your-wine")? "find your wine page" : "HP",
                                                    'tile_position': (index + 1),
                                                    'page_location': window.location.href
                                                }
                                                clickDatalayer(dl)
                                            }}
                                        >
                                            <button className='btn btn-primary-2'>
                                                {item.sub_section_button_text.text}
                                            </button>
                                        </Link>
                                        :
                                        <a 
                                            href={item.sub_section_button_link.url} 
                                            target={(item.sub_section_button_link.target)? item.sub_section_button_link.target : "_self"}
                                            onClick={() => {
                                                let pageHost = new URL(window.location.href)
                                                let dl = {
                                                    'event': 'learn_more_clicked',
                                                    'page_name': item.sub_section_button_link.url,
                                                    'referral': (pageHost.pathname.replace('/', '') === "find-your-wine")? "find your wine page" : "HP",
                                                    'tile_position': (index + 1),
                                                    'page_location': window.location.href
                                                }
                                                clickDatalayer(dl)
                                            }}
                                        >
                                            <button className='btn btn-primary-2'>
                                                {item.sub_section_button_text.text}
                                            </button>
                                        </a>
                                    }
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}