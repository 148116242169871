import React, { useState, useEffect } from 'react'

const FindYourWineBanner = ({ title, backgroundUrl, description, mobileBackgroundUrl }) => {
    const [isMobile, setIsMobile] = useState(false)

    //choose the screen size
    const handleResize = () => {
        if(window.innerWidth < 992){
            setIsMobile(true)
        }else{
            setIsMobile(false)
        }
    }

    //create an event listener
    useEffect(() => {
        handleResize()
        window.addEventListener("resize", handleResize)
    }, [])

    return(
        <section className='in-view find-your-wine__banner'>
            <div className='in-view find-your-wine__banner-bg page-banner zoom-out-bg-wo-opacity' style={{backgroundImage: `url(${(isMobile && mobileBackgroundUrl)? mobileBackgroundUrl : backgroundUrl})`}}></div>
            <div className='find-your-wine__banner-content slide-up py-5 mb-3 mt-3 mt-lg-0'>
                <div className='container mx-auto row justify-content-center align-items-center'>
                    <div className='col-lg-6 p-0'>
                        {(title)?
                            <h2><span>{title}</span></h2>
                        : null}
                    </div>
                    <div className='col-lg-6 p-0'>
                        {(description)?
                            <div className='mx-auto desc-wrapper mt-lg-5 pt-3' dangerouslySetInnerHTML={{ __html: description }}/>
                        : null}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FindYourWineBanner